<template lang="pug">
  .account-list
    AccountList(
      :accounts="accounts"
      :action-title="'actions.get_data_from_ota'"
      :progress-bar-label="$t('components.app_progressbar.getting_data')"
      :progress-data="progressData"
      @action-click="$emit('save-account', $event)"
    )
    .start-import-hint {{ $t("ota_accounts.get_ota_data_button_hint") }}
</template>

<script>
  // misc
  import { reduce } from "lodash-es"

  export default {
    components: {
      AccountList: () => import("@/components/CompanySystem/AccountList")
    },

    props: {
      accounts: {
        type: Array,
        default: () => []
      },
      importsProgress: {
        type: Object,
        default: () => {}
      }
    },

    computed: {
      progressData() {
        return reduce(
          this.accounts,
          (object, account) => {
            const { value, loginFailed, lastImportFinishedAt } = this.importsProgress[account.id] || {}
            object[account.id] = {
              value,
              loginFailed,
              inProgress: account.import_in_progress,
              lastFinishedAt: lastImportFinishedAt
            }

            return object
          },
          {}
        )
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .account-list
    overflow: auto
    margin-top: 15px

    .start-import-hint
      color: $default-gray
      float: right
      font-size: 0.8em
      margin-top: 15px
</style>
